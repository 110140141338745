import type { MaybeRef } from 'vue'
import type { UseQueryOptions } from '@tanstack/vue-query'
import { useQuery } from '@tanstack/vue-query'
import type { Category } from './useFetchCategories'
import { useFetchCategories } from './useFetchCategories'

type Params = {
  lang?: MaybeRef<string>
} & Omit<UseQueryOptions<Category[], Error, Category[]>, 'queryKey' | 'queryFn'>

export function useCategories({ lang, ...params }: Params = {}) {
  const { locale } = useI18n()

  const langToFetch = computed(() => unref(lang ?? locale))
  const { fetchCategories } = useFetchCategories()

  const {
    data: categories,
    refetch,
    ...rest
  } = useQuery({
    queryKey: ['categories', langToFetch],
    queryFn: () => fetchCategories(langToFetch.value),
    ...params
  })

  const prefetch = async () => {
    if (!categories.value) {
      await refetch()
    }
    return categories.value
  }

  return { categories, prefetch, ...rest }
}
