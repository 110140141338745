<template>
  <CommonTooltip
    v-if="shouldIconBeShown"
    :tooltip="$t('cars-list.transport-tooltip')"
  >
    <DynamicAuctionItemListCarIcon
      variant="border"
      size="big"
      v-bind="$attrs"
      @click.stop="isOpen = true"
    >
      <Spinner
        v-if="
          (isCalculationLoading || (areCategoriesLoading && locale !== 'en')) &&
          isOpen
        "
      />
      <i v-else class="ab-icon ab-icon-transport h-full"></i>
    </DynamicAuctionItemListCarIcon>
  </CommonTooltip>

  <CommonDialog :opened="shouldDialogBeShown" @close="isOpen = false">
    <template #title>
      {{ $t('transport-modal.title') }}
    </template>
    <template v-if="calculation" #content>
      <div class="text-lg">
        <i18n-t keypath="transport-modal.calculation-info" tag="div">
          <template #distance>
            <div class="my-2 border-l-2 border-l-primary pl-4">
              <p>
                {{ `${$t('post-code')} D-${calculation.payload.origin.zip}` }}
              </p>
            </div>
          </template>
          <template #destination>
            <div class="my-2 border-l-2 border-l-primary pl-4">
              <p>
                {{
                  profile.extendedData.addressBook.items[
                    profile.extendedData.addressBook.vehicleDeliveryAddress
                  ].companyName
                }}
              </p>
              <p>{{ calculation.payload.destination.street }}</p>
              <p>
                {{
                  `${calculation.payload.destination.zip} ${calculation.payload.destination.city}`
                }}
              </p>
            </div>
          </template>
          <template #amount>
            <span class="font-bold">{{ calculation.result.base_price }} €</span>
          </template>
        </i18n-t>
        <p class="mt-4">{{ $t('transport-modal.additional-info') }}</p>
        <i18n-t
          class="mt-4"
          keypath="transport-modal.additional-info-2"
          tag="p"
        >
          <template #link>
            <CommonLink underline class="text-primary" :href="ADSpUrl">
              (ADSp)
            </CommonLink>
          </template>
        </i18n-t>
        <p class="mt-4 italic">{{ $t('transport-modal.footer') }}</p>
      </div>
    </template>
  </CommonDialog>
</template>

<script setup lang="ts">
import { useProfile } from '@autobid/ui/composables/useProfile'
import type { AuctionCar } from '@autobid/ui/types/Car'
import { parseTransportPayload } from '@autobid/ui/utils/car/parseTransportPayload'
import { type Category } from '@autobid/ui/composables/car/useFetchCategories'
import { useCategories } from '@autobid/ui/composables/car/useCategories'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useQuery } from '@tanstack/vue-query'
import { getError } from '@autobid/strapi-integration/utils/getError'
import Spinner from '@autobid/ui/components/common/Spinner.vue'

type Props = {
  car: AuctionCar
}

interface Offer {
  transport_type: string
  contract_id: string
  supplier_id: string
  base_price: number
  provision: number
  net_price: number
  vat: number
  tax_amount: number
  price: number
  currency: string
  delivery_time: number
}

interface SuccessfulResponse {
  data: {
    success: true
    offers: Offer[]
  }
}

interface UnsuccessfulResponse {
  data: {
    success: false
    error: {
      status_code: number
      code: string
      message: string
      body: {
        statusCode: number
        error: string
        message: string
        error_details: Record<string, any>
        userId: number
        carId: number
        request: string
      }
    }
    message: string
  }
}

type ApiResponse = SuccessfulResponse | UnsuccessfulResponse

const props = defineProps<Props>()

const isOpen = ref(false)
const { locale } = useI18n()
const { data: profile } = useProfile()
const push = usePush()

const ADSpUrl = computed(() => {
  const ADSpLang = locale.value === 'de' ? 'DE' : 'EN'
  return `https://amab-t3.autobid.de/fileadmin/autobid/documents/Downloadcenter/Spedition/ADSp/DSLV-ADSp-2017-${ADSpLang}.pdf`
})

const { categories, isLoading: areCategoriesLoading } = useCategories({
  enabled: computed(() => isOpen.value && locale.value !== 'en'),
  lang: 'en'
})

const getCategoryEngName = (categories: Category[], id: number) => {
  const match = categories.find((category) => category.id === id)
  if (!match) {
    throw new Error('Category not found')
  }
  return match.name
}
const { $customFetch } = useCustomFetch()

const shouldIconBeShown = computed(() => {
  if (!profile.value) return false

  const deliveryId =
    profile.value.extendedData.addressBook.vehicleDeliveryAddress
  const deliveryItem = profile.value.extendedData.addressBook.items[deliveryId]

  const transportEnabled = profile.value.parameters?.transport_enabled === '1'

  const isUserPermitted =
    transportEnabled && deliveryItem.country.isoCode === 'DE'

  return isUserPermitted && props.car.status.transportAvailable
})

const { data: calculation, isLoading: isCalculationLoading } = useQuery({
  queryKey: ['transport-calculation', locale, profile.value?.id, props.car.id],
  queryFn: async () => {
    if (!profile.value) return null

    const categoryNameEng =
      locale.value === 'en'
        ? props.car.category.name
        : getCategoryEngName(categories.value, props.car.category.id)

    const payload = parseTransportPayload({
      categoryNameEng,
      car: props.car,
      profile: profile.value
    })

    const result = await $customFetch<ApiResponse>('/api/backend', {
      method: 'POST',
      body: {
        queryMethod: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        queryApi: 'towTruck',
        queryUrl: '/api/v1/offer/get',
        queryBody: payload
      }
    })
    if (result.data.success === false) {
      throw new Error(result.data.error.message)
    }

    // info: current implementation returns only one offer, so we should be headed to the first one
    return { payload, result: result.data.offers[0] }
  },
  onError: (error: unknown) => {
    push.error(getError(error).message)
    isOpen.value = false
  },
  enabled: computed(() =>
    Boolean(isOpen.value && (categories.value || locale.value === 'en'))
  ),
  cacheTime: 1000 * 60 * 5
})

const shouldDialogBeShown = computed(() => {
  return Boolean(isOpen.value && calculation.value)
})
</script>
