import { useCustomFetch } from '../useHttp'

export type Category = { id: number; name: string }

type Result =
  | {
      data: {
        categoriesPremium: {
          items: Category[]
        }
      }
      error: null
    }
  | { error: unknown; data: null }

export function useFetchCategories() {
  const { $customFetch } = useCustomFetch()

  const fetchCategories = async (lang: string) => {
    const result = await $customFetch<Result>(`/api/categories`, {
      query: { lang }
    })
    if (result.error) {
      throw result.error
    }
    return result.data.categoriesPremium.items
  }

  return { fetchCategories }
}
