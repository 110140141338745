import type { AuctionCar } from '@autobid/ui/types/Car'
import type { UserProfile } from '@autobid/nuxt-pinia-store/types/User'

type Params = {
  car: AuctionCar
  profile: UserProfile
  categoryNameEng: string
}

export function parseTransportPayload({
  car,
  profile,
  categoryNameEng
}: Params) {
  const { equipments } = car
  const { vehicleDeliveryAddress } = profile.extendedData.addressBook
  const address = profile.extendedData.addressBook.items[vehicleDeliveryAddress]

  const vehicleRoadworthy = Boolean(
    equipments.eq129 && ['2', '3'].includes(equipments.eq129?.value)
  )

  const vehicleHeight = Number(equipments.eq1218?.value)
  const vehicleHeightCode =
    vehicleHeight >= 1860
      ? 'lheight'
      : vehicleHeight > 1700
      ? 'mheight'
      : 'sheight'

  const payload = {
    userId: profile.id,
    origin: {
      zip: equipments.eq133.value,
      city: equipments.eq168.value,
      street: equipments.eq164.value,
      country: 114
    },
    destination: {
      street: address.address,
      zip: address.zip,
      city: address.city
    },
    carId: car.id,
    vehicleRoadworthy,
    productCode: vehicleHeightCode,
    manufacturer: car.manufacturer.name,
    model: car.name.replace(car.manufacturer.name, '').trim(),
    category: {
      id: car.category.id,
      name: car.category.name,
      name_eng: categoryNameEng
    },
    tax: 'net'
  }

  return payload
}
